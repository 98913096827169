.teacher-data-wrapper {
  .info-chart-wrapper {
    .scroll-button-wrapper {
      padding: 15px 0;
      align-self: stretch;
      .scroll-button {
        min-width: unset;
        padding: 7px;
        height: 100%;
      }
    }
    @media (min-width: 1500px) or (max-width: 600px) {
      .scroll-button-wrapper {
        display: none;
      }
    }
  }
}
